import proj4 from "proj4";
import { GeorefImage } from "../model";
import * as loadOpenCV from "../wasm-interfaces/opencv_js";
import { ExifDataService } from "./ExifDataService";
import { Cartographic, Terrain, TerrainProvider, sampleTerrain } from "cesium";
import { Log } from "../utils/logging";
import { getHeight, getWidth } from "ol/extent";

const FALLBACK_FOCAL_LENGTH = 3000; // mm
const TAG = "PhotoPosition";

export class PhotoPosition {
    public static async calculate(image: GeorefImage, f_len_override?: number | null): Promise<PhotoPositionResult> {
        Log.info(TAG, "Start calculation");

        if(image.points.length < 3) {
            return Promise.reject("Minimum 3 points required");
        }

        // @ts-ignore
        let cv = await loadOpenCV();

        proj4.defs("EPSG:4978","+proj=geocent +datum=WGS84 +units=m +no_defs +type=crs");
        let width = getWidth(image.extent);
        let height = getHeight(image.extent);

        // add terrain information to map points
        let terrain = await this.getTerainProvider();
        let map_points: number[][] = await sampleTerrain(terrain, 13, image.points.map(p => Cartographic.fromDegrees(p.mapPoint[0], p.mapPoint[1])))
        .then(samples => {
            Log.debug(TAG, "Received elevation samples: " + samples);
            return image.points.map((p, index) => {
                return [p.mapPoint[0], p.mapPoint[1], samples[index].height]
            });
        });

        // convert
        let image_points = cv.matFromArray(
            image.points.length, 
            2, 
            cv.CV_64F,
            image.points
                .map(p => p.imagePoint)
                .reduce(
                    (result, point) => {
                        result.push(...point);
                        return result;
                    }, 
                    []
                )
        );

        Log.debug(TAG, "Image points:\n" + this.printMatrix(image_points));

        map_points = cv.matFromArray(
            map_points.length, 
            3, 
            cv.CV_64F,  
            map_points
                .map(p => {
                    return proj4("EPSG:4326", "EPSG:4978", [p[0], p[1], p[2]])
                })
                .reduce(
                    (result, point) => {
                        result.push(...point);
                        return result;
                    }, 
                    []
                )
        );
        Log.debug(TAG, "Map points:\n" + this.printMatrix(map_points));

        let f_len = f_len_override ? f_len_override : await this.getFocalLength(image);
        Log.debug(TAG, "Focal length: " + f_len + "px");

        // solve equation
        let cx = getWidth(image.extent)/2;
        let cy = getHeight(image.extent)/2;

        let k = cv.matFromArray(
            3,
            3,
            cv.CV_64F,
            [
                f_len, 0, cx,
                0, f_len, cy,
                0, 0, 1
            ]
        );
        Log.debug(TAG, "Guessed camera matrix:\n" + this.printMatrix(k));

        let dist_coeffs = cv.Mat.zeros(4, 1, cv.CV_64F);
        let rvec = cv.Mat.zeros(3, 1, cv.CV_64F);
        let tvec = cv.Mat.zeros(3, 1, cv.CV_64F);
        let success = cv.solvePnP(map_points, image_points, k, dist_coeffs, rvec, tvec, false, cv.SOLVEPNP_SQPNP);
        if (!success) {
            return Promise.reject("Cannot solve pose");
        }
        Log.debug(TAG, "solvePnP result:\nTranslation vector:\n" + this.printMatrix(tvec) + "\nRotation vector:\n" + this.printMatrix(rvec));

        // convert to rotation matrix
        let R = cv.Mat.zeros(3, 3, cv.CV_64F);
        let jacobian = cv.Mat.zeros(3, 9, cv.CV_64F);
        cv.Rodrigues(rvec, R, jacobian);
        Log.debug(TAG, "Rotation matrix:\n" + this.printMatrix(R));

        // get camera position
        let camera_position = new cv.Mat();                        
        let noArray = new cv.Mat();
        cv.gemm(R.t(), tvec, 1.0, noArray, 0.0, camera_position);
        cv.multiply(camera_position, cv.matFromArray(3, 1, cv.CV_64F, [-1.0, -1.0, -1.0]), camera_position);
        let camera_position_lonlat = proj4("EPSG:4978", "EPSG:4326", [].slice.call(camera_position.data64F));
        Log.debug(TAG, "Camera position: " + camera_position_lonlat);

        // get bearing
        let vec_away = new cv.Mat();
        cv.gemm(R.t(), cv.matFromArray(3, 1, cv.CV_64F, [0, 0, 100]), 1, new cv.Mat(), 0, vec_away);
        let target_point = new cv.Mat();
        cv.add(camera_position, vec_away, target_point);
        let target_point_lonlat = proj4("EPSG:4978", "EPSG:4326", [].slice.call(target_point.data64F));
        let bearing = this.getHorizontalBearing(
            camera_position_lonlat[0], camera_position_lonlat[1],
            target_point_lonlat[0], target_point_lonlat[1]
        );
        Log.debug(TAG, "Bearing: " + bearing);

        // get tilt
        let camera_position_rev = new cv.Mat();
        cv.multiply(camera_position, cv.matFromArray(3, 1, cv.CV_64F, [-1.0, -1.0, -1.0]), camera_position_rev);
        let tilt = this.getAngleBetweenVectors(cv, vec_away, camera_position_rev);
        Log.debug(TAG, "Tilt: " + tilt);

        // get roll
        let vec_right = new cv.Mat();
        cv.gemm(R.t(), cv.matFromArray(3, 1, cv.CV_64F, [100, 0, 0]), 1, new cv.Mat(), 0, vec_right);
        let roll = (90 - this.getAngleBetweenVectors(cv, vec_right, camera_position_rev));
        Log.debug(TAG, "Roll: " + roll);

        let fov_x = this.rad2deg(2 * Math.atan(width/(2*f_len)));
        let fov_y = this.rad2deg(2 * Math.atan(height/(2*f_len)));
        Log.debug(TAG, "FOV x:" + fov_x + " y:" + fov_y);

        // get edge points for Cesium
        let plane_distance = 100;
        let plane_width = 2 * plane_distance * Math.tan(this.deg2rad(fov_x)/2);
        let plane_height = 2 * plane_distance * Math.tan(this.deg2rad(fov_y)/2);

        let image_plane = [
            // top left
            [-plane_width/2, plane_height/2, plane_distance],
            // top right
            [plane_width/2, plane_height/2, plane_distance],
            // bottom right
            [plane_width/2, -plane_height/2, plane_distance],
            // bottom left
            [-plane_width/2, -plane_height/2, plane_distance]
        ].map(point => {
            let camera_to_point_vec = new cv.Mat();
            cv.gemm(R.t(), cv.matFromArray(3, 1, cv.CV_64F, point), 1, new cv.Mat(), 0, camera_to_point_vec);
            
            let target_point = new cv.Mat();
            cv.add(camera_position, camera_to_point_vec, target_point);

            return proj4("EPSG:4978", "EPSG:4326", [].slice.call(target_point.data64F));
        })

        Log.info(TAG, "Done");
        return {
            lon: camera_position_lonlat[0],
            lat: camera_position_lonlat[1],
            alt: camera_position_lonlat[2],
            heading: bearing,
            tilt: tilt,
            roll: roll,
            fov_x: fov_x,
            fov_y: fov_y,
            image_plane: image_plane
        }
    }

    private static getTerainProvider(): Promise<TerrainProvider> {
        return new Promise((acc, rej) => {
            let terrain = Terrain.fromWorldTerrain();
            terrain.readyEvent.addEventListener(provider => {
                acc(provider);
            });
        });
    }

    private static printMatrix(matrix: any): string {
        let result = "";
        for (let y = 0; y < matrix.rows; y++) {
            for (let x = 0; x < matrix.cols; x++) {
                result += matrix.doubleAt(y, x) + " ";
            }
            result += "\n"
        }
        return result;
    }

    private static getFocalLength(image: GeorefImage): Promise<number> {
        return new Promise((acc, rej) => {
            ExifDataService.getData(image.url)
            .then(exif => {
                Log.debug(TAG, "Received exif data: " + JSON.stringify(exif));
                
                if(exif.FocalLength && exif.FocalLengthIn35mmFilm) {
                    Log.debug(TAG, "Use focal length and focal length 35mm equvalent method")
                    let crop_factor = exif.FocalLengthIn35mmFilm/exif.FocalLength;
                    Log.debug(TAG, "Crop factor: " + crop_factor);
                    let diag = 43.3/crop_factor;
                    Log.debug(TAG, "Sensor diagonal: " + diag + "mm");
                    let sensor_width = ((3 * diag) / Math.sqrt(13));
                    Log.debug(TAG, "Sensor width: " + sensor_width + "mm");
                    let width = getWidth(image.extent);
                    acc(width * exif.FocalLength / sensor_width);
                }

                acc(FALLBACK_FOCAL_LENGTH);
            })
            .catch(e => {
                Log.warn(TAG, "Cannot read exif data: " + e);
                acc(FALLBACK_FOCAL_LENGTH);
            });
        })
    }

    private static rad2deg(rad: number) {
        return (rad * 180.0) / Math.PI;
    }

    private static deg2rad(deg: number) { 
        return deg * (Math.PI / 180.0);
    }

    private static getAngleBetweenVectors(cv: any, a: any, b: any) {
        return this.rad2deg(Math.acos(a.dot(b) / (cv.norm(a) * cv.norm(b))));
    }

    private static getHorizontalBearing(fromLon: number, fromLat: number, toLon: number, toLat: number) {
        fromLon = this.deg2rad(fromLon);
        fromLat = this.deg2rad(fromLat);
        toLon = this.deg2rad(toLon);
        toLat = this.deg2rad(toLat);
        
        const deltaLon = toLon - fromLon;
        
        const y = Math.sin(deltaLon) * Math.cos(toLat);
        const x = Math.cos(fromLat) * Math.sin(toLat) - Math.sin(fromLat) * Math.cos(toLat) * Math.cos(deltaLon);
        
        const bearing = Math.atan2(y, x);
        
        // Bearing in range [0, 2*PI)
        return this.rad2deg((bearing + 2.0 * Math.PI) % (2.0 * Math.PI));
    }
}

export interface PhotoPositionResult {
    lon: number;
    lat: number;
    alt: number;
    heading: number;
    tilt: number;
    roll: number;
    fov_x: number;
    fov_y: number;
    image_plane: number[][];
}

import { Crop } from "react-image-crop";

export function setLeft(extent: number[], value: number) {
    extent[0] = value;
}

export function setBottom(extent: number[], value: number) {
    extent[1] = value;
}

export function setRight(extent: number[], value: number) {
    extent[2] = value;
}

export function setTop(extent: number[], value: number) {
    extent[3] = value;
}

/**
 * Converts a crop to a openlayers extent (left, bottom, right, top) 
 * with x from left to right and y from bottom to top
 * @param crop 
 * @param width 
 * @param height 
 * @param scale 
 * @returns 
 */
export function cropToExtent(crop: Crop, height: number, scale: number): number[] {
    let left = crop.x * scale;
    let bottom = (height - (crop.y + crop.height)) * scale;
    let right = (crop.x + crop.width) * scale;
    let top = (height - crop.y) * scale;

    return [left, bottom, right, top];
}

/**
 * Convert a openlayers extent (left, bottom, right, top) to a crop object
 * @param extent
 * @param scale 
 * @returns 
 */
export function extentToCrop(extent: number[], height: number, scale: number): Crop {
    const [left, bottom, right, top] = extent;
    const cropWidth = (right - left) * scale;
    const cropHeight = (top - bottom) * scale;
    const x = left * scale;
    const y = (height - top) * scale; // Adjusting y to match the coordinate system

    return {
        x: x,
        y: y,
        width: cropWidth,
        height: cropHeight,
        unit: "px"
    };
}
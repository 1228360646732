import { useState } from "react";
import { Link, Outlet, useLoaderData, useParams } from "react-router-dom";
import { GeorefImage } from "../model";

export function ImageLayout() {
    const [expanded, setExpanded] = useState(false);
    const image = useLoaderData() as GeorefImage;
    const params = useParams();

    const groups: MenuGroup[] = [
        {
            name: "General",
            items: [
                {
                    name: "Info",
                    icon: <i className="bi bi-info-circle-fill"></i>,
                    tooltip: "Get general information about image",
                    isEnabled: () => true,
                    getHref: () => `/projects/${params.project_id}/images/${params.image_id}`
                }
            ]
        },
        {
            name: "View",
            items: [
                {
                    name: "Map",
                    icon: <i className="bi bi-map-fill"></i>,
                    tooltip: "View image overlayed in map",
                    isEnabled: () => image.points.length >= 3,
                    getHref: () => `/projects/${params.project_id}/images/${params.image_id}/view`
                },
                {
                    name: "3D",
                    icon: <i className="bi bi-box"></i>,
                    tooltip: "View image overlay in 3D space",
                    isEnabled: () => image.points.length >= 3,
                    getHref: () => `/projects/${params.project_id}/images/${params.image_id}/3d`
                }
            ]
        },
        {
            name: "Edit",
            items: [
                {
                    name: "Split",
                    icon: <i className="bi bi-layout-split"></i>,
                    tooltip: "Edit reference points in split view",
                    isEnabled: () => true,
                    getHref: () => `/projects/${params.project_id}/images/${params.image_id}/edit/split`
                },
                {
                    name: "Overlay",
                    icon: <i className="bi bi-layers-fill"></i>,
                    tooltip: "Edit reference points in overlay view",
                    isEnabled: () => image.points.length >= 3,
                    getHref: () => `/projects/${params.project_id}/images/${params.image_id}/edit/overlay`
                },
                {
                    name: "Crop",
                    icon: <i className="bi bi-crop"></i>,
                    tooltip: "Crop the image",
                    isEnabled: () => true,
                    getHref: () => `/projects/${params.project_id}/images/${params.image_id}/edit/crop`
                }
            ]
        }
    ];

    return <div className="flex h-full flex-col md:flex-row">
        <div className="bg-neutral-700 md:h-full">

            <div className="m-3 hidden md:block p-2 hover:bg-neutral-800 cursor-pointer rounded" onClick={() => setExpanded(!expanded)}>
                { expanded ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i> }
            </div>

            <div className="flex md:block items-center justify-center">
                { groups.map((group, index) => {
                    return <div className={`inline-block md:block text-sm md:text-base ${!expanded ? "md:border-t" : ""} border-neutral-600`} key={group.name}>

                        { expanded && <div className="flex w-full px-3 text-sm text-neutral-400">
                            <div>{group.name}</div>
                            <div className="ml-1 flex-1 h-[1px] mt-3 bg-neutral-600"></div>
                        </div> }

                        { group.items.map(item => {
                            let itemElement = <div className={`m-2 md:m-3 p-2 md:flex inline-flex cursor-pointer rounded ${item.isEnabled() ? "hover:bg-neutral-800" : "text-neutral-400"}`}>
                                <div>{item.icon}</div>
                                { expanded && <div className="flex-1 ml-3">{item.name}</div> }
                            </div>;

                            return item.isEnabled() ? <Link to={item.getHref()}>{itemElement}</Link> : itemElement
                        })}
                    </div>
                })}
            </div>
        </div>
        <div className="flex-1 h-full">
            <Outlet/>
        </div>
    </div>
}

interface MenuGroup {
    name: string;
    items: MenuItem[];
}

interface MenuItem {
    name: string;
    icon: any;
    tooltip: string;
    isEnabled: () => boolean;
    getHref: () => string;
}
import { useState } from "react";
import { Button } from "@joushx/react-components";
import { toast } from "react-toastify";
import { GeorefImage } from "../model";
import { v4 } from "uuid";
import { StorageService } from "../services/StorageService";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "../components/Container";

export default function NewImage() {

    const [url, setUrl] = useState<string|null>(null);
    const [jsonPath, setJsonPath] = useState<FileList|null>(null);
    const navigate = useNavigate();
    const params = useParams();

    function getImageExtent(url: string): Promise<number[]> {
        return new Promise((acc) => {
            const img = new Image();
            img.onload = function(e) {
                let elem = e.currentTarget as HTMLImageElement;
                let left = 0;
                let bottom = 0;
                let right = elem.width;
                let top = elem.height;
                acc([left, bottom, right, top]);
            }
            img.src = url;
        })
    }

    function addImage() {
        if (!url) {
            toast.error("No URL");
            return;
        }

        getImageExtent(url).then(extent => {
            let image: GeorefImage = {
                id: v4(),
                url: url,
                points: [],
                extent: extent
            }
            
            StorageService.addImage(params.project_id as string, image);
            navigate(`/projects/${params.project_id}/images/${image.id}`);
        });
    }

    function setUrlFromUpload(files: FileList | null) {
        if(!files || files.length === 0) {
            toast.error("No file selected");
            return;
        }

        let file = files[0];
        
        const reader = new FileReader();

        reader.addEventListener("load", () => {
                setUrl(reader.result as string);
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function importImage() {
        if(!jsonPath) {
            toast.error("No file selected");
            return;
        }

        for(let i = 0; i < jsonPath.length; i++) {
            let file = jsonPath?.item(i);

            if(!file) {
                continue
            }

            if (file.type && file.type !== 'application/json') {
                toast.error("No JSON file");
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                let text = e.target?.result;
                if(!text) {
                    toast.error("Cannot read file");
                    return;
                }

                let image = JSON.parse(text as string);
                
                StorageService.addImage(params.project_id as string, image as GeorefImage);

                toast.info("Import successful");
            });
            reader.readAsText(file);
        }
    }

    return (
        <Container>
            <h1 className="text-3xl">Add new Image</h1>

            <h2 className="text-xl mt-10">From URL</h2>
            <div className="flex mt-5 w-full">
                <input name="url" type="url" className="rounded bg-neutral-600 w-96 p-1" placeholder="https://..." id="url" onChange={e => setUrl(e.target.value)}/>
                <div className="inline-block ml-5">
                    <Button onClick={() => addImage()}>load</Button>
                </div>
            </div>

            <h2 className="text-xl mt-10">From File Upload</h2>

            <div className="flex mt-5 w-full">
                <input name="file" type="file" className="rounded bg-neutral-600 w-96 p-1" id="file" onChange={e => setUrlFromUpload(e.target.files)}/>
                <div className="inline-block ml-5">
                    <Button onClick={() => addImage()}>upload</Button>
                </div>
            </div>

            <h2 className="text-xl mt-10">Import from JSON file</h2>

            <div className="flex mt-5 w-full">
                <input name="file" type="file" className="rounded bg-neutral-600 w-96 p-1" id="file" onChange={e => setJsonPath(e.target.files)}/>
                <div className="inline-block ml-5">
                    <Button onClick={() => importImage()}>import</Button>
                </div>
            </div>
        </Container>
    )
}
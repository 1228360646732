import { GeorefImage, Project } from "../model";
import { Log } from "../utils/logging";

const KEY = "projects";
const TAG = "StorageService";

export class StorageService {
    public static getProjects(): Project[] {
        const storageContent = window.localStorage.getItem(KEY);
        if (storageContent == null) {
            return [];
        }

        return JSON.parse(storageContent);
    }

    public static getProject(id: string): Project | undefined {
        let projects = this.getProjects();
        return projects.find(p => p.id === id);
    }

    public static addImage(project_id: string, image: GeorefImage) {
        let projects = this.getProjects();
        let projectIndex = projects.findIndex(p => p.id === project_id);
        if(projectIndex === -1) {
            Log.error(TAG, "Cannot find project with id " + project_id);
            return;
        }
        projects[projectIndex].images.push(image);
        window.localStorage.setItem(KEY, JSON.stringify(projects));
    }

    public static addProject(project: Project) {
        let projects = this.getProjects();
        projects.push(project);
        window.localStorage.setItem(KEY, JSON.stringify(projects));
    }

    public static getImage(project_id: string, id: string): GeorefImage | undefined {
        let project = this.getProject(project_id);
        return project?.images.find(i => i.id === id);
    }

    public static updateImage(project_id: string, image: GeorefImage) {
        let projects = this.getProjects();
        let projectIndex = projects.findIndex(p => p.id === project_id);
        let imageIndex = projects[projectIndex].images.findIndex(i => i.id === image.id);
        projects[projectIndex].images[imageIndex] = image;
        window.localStorage.setItem(KEY, JSON.stringify(projects));
    }

    public static updateProject(project: Project) {
        let projects = this.getProjects();
        let projectIndex = projects.findIndex(p => p.id === project.id);
        projects[projectIndex] = project;
        window.localStorage.setItem(KEY, JSON.stringify(projects));
    }
}
import React from "react";
import { View } from "ol";
import { Projection } from "ol/proj";
import Map from 'ol/Map.js';
import ImageLayer from "ol/layer/Image";
import { useLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { PointsLayer } from "../components/PointsLayer";
import { GpsMapCoordinate, ImageCoordinate, OlImageCoordinate, OlMapCoordinate, getDefaultLayers, zoomToImage } from "../util";
import { GeorefImage } from "../model";
import { StorageService } from "../services/StorageService";
import { toast } from "react-toastify";
import LayerSwitcher from "ol-layerswitcher";
import { getHeight } from "ol/extent";
import ImageStatic from "ol/source/ImageStatic";

export function SplitEditor() {
    const mapDiv = React.useRef(null);
    const imageDiv = React.useRef(null);
    const image = useLoaderData() as GeorefImage;
    const params = useParams();
    const revalidator = useRevalidator();

    let mapPointsLayer: PointsLayer;
    let imagePointsLayer: PointsLayer;

    function initMap() {
        let pointsLayer = new PointsLayer(image.points.map(p => new GpsMapCoordinate(p.mapPoint[0], p.mapPoint[1]).toOl().toArray()));
        mapPointsLayer = pointsLayer;

        if(!mapDiv.current) {
            toast.error("Cannot find map");
            return;
        }

        let layers = getDefaultLayers();
        layers.push(pointsLayer);

        let map = new Map({
            layers: layers,
            target: mapDiv.current,
            view: new View({
              projection: "EPSG:3857",
              center: new GpsMapCoordinate(0, 0).toOl().toArray(),
              zoom: 1
            })
        });

        var layerSwitcher = new LayerSwitcher({
            tipLabel: 'Legende',
            groupSelectStyle: 'children'
        });
        map.addControl(layerSwitcher);

        zoomToImage(image, map);

        map.on("click", e => {
            let c = map.getCoordinateFromPixel(e.pixel);
            pointsLayer.handleClick(c);
            checkDone();
        });
    }

    function initImage() {
        let projection = new Projection({
            code: "image",
            units: 'pixels',
            extent: image.crop ? image.crop : image.extent
        });

        let pointsLayer = new PointsLayer(image.points.map(p => new ImageCoordinate(p.imagePoint[0], p.imagePoint[1]).flip(getHeight(image.extent)).toArray()));
        imagePointsLayer = pointsLayer;

        if(!imageDiv.current) {
            toast.error("Cannot find image");
            return;
        }

        let map = new Map({
            layers: [
                new ImageLayer({
                    source: new ImageStatic({
                        crossOrigin: "anonymous",
                        url: image.url,
                        imageExtent: image.extent,
                        projection: projection,
                    })
                }),
                pointsLayer,
            ],
            target: imageDiv.current,
            view: new View({
                projection: projection,
                center: [0,0],
                zoom: 1
            })
        });

        map.on("click", e => {
            let feature = map.forEachFeatureAtPixel(e.pixel, (feature, layer, geometry) => {
                return feature;
            });

            if(feature) {
                let index = feature.getProperties().properties["pointIndex"];
                pointsLayer.handleSelect(index);
                return;
            }

            pointsLayer.handleClick(map.getCoordinateFromPixel(e.pixel));
            checkDone();
        });
    }

    function checkDone() {
        let imageNew = imagePointsLayer.getNew();
        let mapNew = mapPointsLayer.getNew();

        if (imageNew == null || mapNew == null) {
            return;
        }

        let typedImageNew = new OlImageCoordinate(imageNew[0], imageNew[1]);
        let typedMapNew = new OlMapCoordinate(mapNew[0], mapNew[1]);

        image.points.push({
            mapPoint: typedMapNew.toGps().toArray(),
            imagePoint: typedImageNew.flip(getHeight(image.extent)).toArray()
        });
        
        StorageService.updateImage(params.project_id as string, image);
        imagePointsLayer.addPoint(imageNew);
        mapPointsLayer.addPoint(mapNew);

        imagePointsLayer.clearNew();
        mapPointsLayer.clearNew();

        revalidator.revalidate();
    }

    React.useEffect(() => {
        initMap();
        initImage();
    }, []);

    return <div className="h-full flex flex-col md:flex-row">
        <div className="h-full flex-1" ref={mapDiv} />
        <div className="h-full flex-1" ref={imageDiv} />
    </div>
}

export interface SideToSideProps {
    images: GeorefImage[];
    onImageChanged: (image: GeorefImage) => void;
}

import { useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { useLoaderData, useParams } from "react-router-dom";
import { GeorefImage } from "../model";
import 'react-image-crop/dist/ReactCrop.css'
import { StorageService } from "../services/StorageService";
import { toast } from "react-toastify";
import { cropToExtent, extentToCrop } from "../utils/extent";

export function CropView() {
    const image = useLoaderData() as GeorefImage;
    const imageElement = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const params = useParams();

    function onImageLoaded(e: Event) {
        let target = e.target as HTMLImageElement;
        let scale = target.width / target.naturalWidth;
        let extent = image.crop ? image.crop : image.extent;

        let crop = extentToCrop(extent, target.height, scale)
        setCrop(crop);
    }

    function onComplete(crop: Crop) {
        if(!imageElement.current) {
            toast.error("No image element found");
            return;
        }

        let scale = imageElement.current.width / imageElement.current.naturalWidth;
        image.crop = cropToExtent(crop, imageElement.current.height, scale);
        StorageService.updateImage(params.project_id as string, image);
    }
    
    return <ReactCrop crop={crop} onChange={setCrop} onComplete={onComplete} className="h-screen">
        <img src={image.url} alt={image.id} ref={imageElement} onLoad={e => onImageLoaded(e as any)} className="object-scale-down max-h-screen"/>
    </ReactCrop>
}

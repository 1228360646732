import React, { useEffect, useRef } from "react";
import { Viewer, Cartesian3, Math, Terrain, createOsmBuildingsAsync, Color, ImageMaterialProperty, Ion } from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { useLoaderData } from "react-router-dom";
import { PhotoPosition } from "../services/PhotoPositionService";
import { GeorefImage } from "../model";
import { Log } from "../utils/logging";
import { toast } from 'react-toastify';

const TAG = "CesiumCombinedViewer";

export function CesiumCombinedViewer() {
    const cesiumDiv = React.useRef<HTMLDivElement>(null);
    const images = useLoaderData() as GeorefImage[];
    const views = useRef<any>();
    const viewer = useRef<any>();

    useEffect(() => {
        Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZjAxZjQzMy1kMzMwLTQ5MmItYmM0Mi1jYzhmNmI0ZWI4YjMiLCJpZCI6MTAzMDY3LCJpYXQiOjE2NTkxODExODl9.vIiTlB9AD804mycPL6dZ-ni90uhmtqlTwKagawr6JZ0";
        
        (window as Record<string, any>)['CESIUM_BASE_URL'] = '/cesium/';

        Promise.all(images.map(image => PhotoPosition.calculate(image, null))).then(results => {
            Log.debug(TAG, "Received calculation result: " + JSON.stringify(results));

            let terrain = Terrain.fromWorldTerrain();

            viewer.current = new Viewer(cesiumDiv.current as Element, {
                animation: false,
                timeline: false,
                baseLayerPicker: true,
                navigationHelpButton: false,
                geocoder: false,
                sceneModePicker: false,
                terrain: terrain,
            });

            createOsmBuildingsAsync().then(t => viewer.current.scene.primitives.add(t)) 

            setTimeout(() => {

                let locations = results.map(result => Cartesian3.fromDegrees(
                    result.lon,
                    result.lat,
                    result.alt
                ));

                let entities: any[] = [];
                images.forEach((image, index) => {
                    let result = results[index];

                    let coordinates: number[] = result.image_plane.reduce((prev, current) => {
                        prev.push(current[0]);
                        prev.push(current[1]);
                        prev.push(current[2]);
                        return prev;
                    }, []);
    
                    entities.push(viewer.current.entities.add({
                        name: image.id,
                        polygon: {
                            hierarchy: Cartesian3.fromDegreesArrayHeights(coordinates),
                            material: new ImageMaterialProperty({
                                image: image.url,
                                color: new Color(1, 1, 1, 1.0),
                            }),
                            perPositionHeight: true,
                            outline: true,
                            outlineColor: Color.WHITE,
                            outlineWidth: 5
                        }
                    }));
                })

                views.current = results.map((result, index) => {
                    return {
                        destination: locations[index],
                        orientation: {
                            heading: Math.toRadians(result.heading),
                            pitch: Math.toRadians(result.tilt-90),
                            roll: Math.toRadians(result.roll),
                        },
                    };
                });

                viewer.current.camera.setView(views.current[0]);

                document.addEventListener('keydown', function (evt) {
                    if (evt.key === 'Shift') {
                        entities.forEach(e => {
                            // @ts-ignore
                            e.polygon.show = false;
                            evt.preventDefault();
                        })
                    }
                });
                
                document.addEventListener('keyup', function (evt) {
                    if (evt.key === 'Shift') {
                        entities.forEach(e => {
                            // @ts-ignore
                            e.polygon.show = true;
                            evt.preventDefault();
                        })
                    }
                });
            });

        }).catch(e => {
            toast.error("Unable to generate 3D view: " + e);
        });
    }, [images]);

    function flyToIndex(index: number) {
        if(!viewer.current) {
            return
        };

        if(!views.current || !views.current[index]) {
            return;
        }

        viewer.current.camera.flyTo(views.current[index]);
    }

    return <div className="md:flex h-full">
        <div className="w-96 p-2">
            <div className="grid grid-cols-4 md:grid-cols-4 gap-2">
                {images.map((img, index) => {
                    return <img alt={img.id} src={img.url} onClick={() => flyToIndex(index)} className="cursor-pointer w-full rounded object-cover aspect-square"/>
                })}
            </div>
        </div>
        <div ref={cesiumDiv} className="h-full"></div>
    </div>
}

import React, { useEffect } from "react";
import { Viewer, Cartesian3, Math, Terrain, createOsmBuildingsAsync, defined, ScreenSpaceEventType, Ellipsoid, Color, buildModuleUrl, OpenStreetMapImageryProvider, ProviderViewModel, WebMapTileServiceImageryProvider, ImageMaterialProperty, Ion } from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { PhotoPosition } from "../services/PhotoPositionService";
import { GeorefImage } from "../model";
import { Log } from "../utils/logging";
import { toast } from 'react-toastify';

const TAG = "CesiumViewer";

export function CesiumViewer() {
    const cesiumDiv = React.useRef<HTMLDivElement>(null);
    const image = useLoaderData() as GeorefImage;
    const [params] = useSearchParams();

    useEffect(() => {
        (window as Record<string, any>)['CESIUM_BASE_URL'] = '/cesium/';

        let f_len_override = params.get("f_len");

        Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZjAxZjQzMy1kMzMwLTQ5MmItYmM0Mi1jYzhmNmI0ZWI4YjMiLCJpZCI6MTAzMDY3LCJpYXQiOjE2NTkxODExODl9.vIiTlB9AD804mycPL6dZ-ni90uhmtqlTwKagawr6JZ0";

        PhotoPosition.calculate(image, f_len_override ? parseInt(f_len_override) : null).then(result => {

            Log.debug(TAG, "Received calculation result: " + JSON.stringify(result));

            let terrain = Terrain.fromWorldTerrain();

            const viewer = new Viewer(cesiumDiv.current as Element, {
                animation: false,
                timeline: false,
                baseLayerPicker: true,
                navigationHelpButton: false,
                geocoder: false,
                sceneModePicker: false,
                terrain: terrain,
            });

            let orthophoto = new ProviderViewModel({
                    name: "basemap.at Orthophoto",
                    iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                    tooltip: "",
                    creationFunction: () => {
                        return new WebMapTileServiceImageryProvider({
                            url: "https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.jpeg",
                            layer: "bmaporthofoto30cm",
                            style: "normal",
                            tileMatrixSetID: "google3857"
                        });
                    }
                });

            viewer.baseLayerPicker.viewModel.selectedImagery = orthophoto;
            viewer.baseLayerPicker.viewModel.imageryProviderViewModels = [
                new ProviderViewModel({
                    name: "OSM",
                    iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                    tooltip: "",
                    creationFunction: () => {
                        return new OpenStreetMapImageryProvider({})
                    }
                }),
                new ProviderViewModel({
                    name: "basemap.at Map",
                    iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                    tooltip: "",
                    creationFunction: () => {
                        return new WebMapTileServiceImageryProvider({
                            url: "https://mapsneu.wien.gv.at/basemap/geolandbasemap/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png",
                            layer: "geolandbasemap",
                            style: "normal",
                            tileMatrixSetID: "google3857"
                        });
                    }
                }),
                orthophoto,
                new ProviderViewModel({
                    name: "basemap.at Elevation",
                    iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                    tooltip: "",
                    creationFunction: () => {
                        return new WebMapTileServiceImageryProvider({
                            url: "https://mapsneu.wien.gv.at/basemap/bmapgelaende/{Style}/google3857/{TileMatrix}/{TileRow}/{TileCol}.jpeg",
                            layer: "bmapgelaende",
                            style: "normal",
                            tileMatrixSetID: "google3857"
                        });
                    }
                }),
                new ProviderViewModel({
                    name: "basemap.at Labels",
                    iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                    tooltip: "",
                    creationFunction: () => {
                        return new WebMapTileServiceImageryProvider({
                            url: "https://mapsneu.wien.gv.at/basemap/bmapoverlay/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png",
                            layer: "bmapoverlay",
                            style: "normal",
                            tileMatrixSetID: "google3857"
                        });
                    }
                }),
                new ProviderViewModel({
                    name: "ArcGis Orthophoto",
                    iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
                    tooltip: "",
                    creationFunction: () => {
                        return new WebMapTileServiceImageryProvider({
                            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{TileMatrix}/{TileRow}/{TileCol}",
                            layer: "foo",
                            style: "bar",
                            tileMatrixSetID: "google3857"
                        });
                    }
                }),
            ];

            createOsmBuildingsAsync().then(t => viewer.scene.primitives.add(t))  

            setTimeout(() => {
                let location = Cartesian3.fromDegrees(
                    result.lon,
                    result.lat,
                    result.alt
                );

                let view = {
                    destination: location,
                    orientation: {
                        heading: Math.toRadians(result.heading),
                        pitch: Math.toRadians(result.tilt-90),
                        roll: Math.toRadians(result.roll),
                    },
                };

                viewer.scene.globe.baseColor=Color.BLACK;
                viewer.camera.setView(view);

                viewer.homeButton.viewModel.command.beforeExecute.addEventListener(function(e) {
                    e.cancel = true;
                    viewer.scene.camera.flyTo(view);
                });


                let coordinates: number[] = result.image_plane.reduce((prev, current) => {
                    prev.push(current[0]);
                    prev.push(current[1]);
                    prev.push(current[2]); // + terrain_height);
                    return prev;
                }, []);

                let image_entity = viewer.entities.add({
                    name: image.id,
                    polygon: {
                        hierarchy: Cartesian3.fromDegreesArrayHeights(coordinates),
                        material: new ImageMaterialProperty({
                            image: image.url,
                            color: new Color(1, 1, 1, 1.0)
                        }),
                        perPositionHeight: true,
                        outline: true,
                        outlineColor: Color.WHITE,
                        outlineWidth: 5
                    }
                });

                viewer.entities.add({
                    name: "camera",
                    position: location,
                    billboard: {
                        sizeInMeters: true,
                        width: 50,
                        height: 50,
                        image: "https://icons.getbootstrap.com/assets/icons/camera.svg"
                    }
                })

                document.addEventListener('keydown', function (evt) {
                    if (evt.key === 'Shift') {
                        // @ts-ignore
                        image_entity.polygon.fill = false;
                        evt.preventDefault();
                    }
                });
                
                document.addEventListener('keyup', function (evt) {
                    if (evt.key === 'Shift') {
                        // @ts-ignore
                        image_entity.polygon.fill = true;
                        evt.preventDefault();
                    }
                });

                viewer.screenSpaceEventHandler.setInputAction((movement: any) => {
                    let ray = viewer.camera.getPickRay(movement.position);
                    if(!ray) {
                        return;
                    }

                    let position = viewer.scene.globe.pick(ray, viewer.scene);

                    if (defined(position)) {

                        // add altitude to get a position position
                        let cartographic = Ellipsoid.WGS84.cartesianToCartographic(position);
                        cartographic.height += 1000;
                        let viewing_position = Ellipsoid.WGS84.cartographicToCartesian(cartographic);

                        viewer.camera.flyTo({
                            destination: viewing_position,
                            orientation: {
                                heading: view.orientation.heading,
                            }
                        });
                    }
                }, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
            });
        }).catch(e => {
            toast.error("Unable to generate 3D view: " + e);
        });
    }, [image, params]);

    return <div className="h-full">
        <div ref={cesiumDiv} className="h-full"></div>
    </div>
}
import ImageLayer from "ol/layer/Image";
import { Projection, addCoordinateTransforms } from "ol/proj";
import Static from "ol/source/ImageStatic";
import { mapToImage, imageToMap, calculate } from "../services/GdalService";
import { OlMapCoordinate, ImageCoordinate, OlImageCoordinate, GpsMapCoordinate } from "../util";
import { getHeight } from "ol/extent";

export class GeorefLayer extends ImageLayer<Static> {

    private projection: Projection;

    constructor(private options: GeorefLayerOptions) {
        super({})
        this.projection = new Projection({
            code: options.id,
            units: 'pixels',
            extent: options.extent
        });
      
        addCoordinateTransforms(
            'EPSG:3857',
            this.projection,
            coords => {
                // map to image
                let height = getHeight(options.extent);
                let typedCoords = new OlMapCoordinate(coords[0], coords[1]);
                let result: ImageCoordinate = mapToImage(typedCoords.toGps());
                return result.flip(height).toArray();
            },
            coords => {
                // image to map
                let height = getHeight(options.extent);
                let typedCoords = new OlImageCoordinate(coords[0], coords[1]);
                let result = imageToMap(typedCoords.flip(height)).toOl().toArray();
                return result;
            }
        );

        this.setSource(new Static({
            url: options.url,
            projection: this.projection,
            imageExtent: options.extent
        }));

        let this_ = this;
        document.addEventListener('keydown', function (evt) {
            if (evt.key === 'Shift') {
                this_.setOpacity(0.0);
                evt.preventDefault();
            }
        });

        document.addEventListener('keyup', function (evt) {
            if (evt.key === 'Shift') {
                this_.setOpacity(1.0);
            }
        });

        document.addEventListener("touchstart", function(_e) {
            this_.setOpacity(0.0);
        });

        document.addEventListener("touchend", function(_e) {
            this_.setOpacity(1.0);
        });
    }

    public getProjection() {
        return this.projection;
    }

    public setPoints(mapPoints: GpsMapCoordinate[], imagePoints: ImageCoordinate[]): boolean {
        let success = calculate(mapPoints, imagePoints, 0);

        return success;
    }
}

export  interface GeorefLayerOptions {
    id: string;
    url: string;
    extent: number[];
}
